<template>
  <!-- 租车城市管理 -->
  <div class="caterCityManage">
    <div class="header">
      <p>包车城市管理</p>
      <el-button
        v-if="listFind('新增城市')"
        type="primary"
        size="small"
        @click="show = true"
      >
        <i
          class="iconfont its_tianjia"
          style="position: relative; top: 1px"
        ></i>
        新增城市
      </el-button>
    </div>
    <Table
      :col-class-type="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('删除')"
            type="text"
            size="small"
            sort="danger"
            @click="deleteFun(scope.scopeRow)"
            >删除</el-button
          >
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      header-text="新增包车城市"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <div class="content">
        选择城市：
        <el-cascader
          ref="cascader"
          :options="cityList"
          :props="props"
          @change="handleCascaderCity"
        ></el-cascader>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  queryCharteredCityList,
  saveCharteredCity,
  updateCharteredCity,
  deleteCharteredCityById,
  getCityCodeAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      show: false,
      tableData: [],
      titleName: [
        {
          title: "城市名称",
          props: "name",
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  let obj = {
                    status: params.status === 0 ? 1 : 0,
                    id: params.id,
                    name: params.name,
                    adCode: params.adCode,
                    adCodes: params.adCodes,
                    center: params.center,
                    level: params.level,
                  };
                  this.updateData(obj);
                },
              },
            });
          },
        },
      ],
      cityId: null,
      cityList: [],
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      form: {
        name: "",
        adCode: "",
        center: "",
        level: "",
      },
    };
  },
  mounted() {
    this.renderData();
    this.getCityCode();
  },
  methods: {
    // 点击表格删除按钮
    deleteFun(scope) {
      this.$confirm("确认删除吗？", "提示")
        .then(() => {
          deleteCharteredCityById(scope.id).then((res) => {
            if (res.code == "SUCCESS") {
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 表格数据更新接口方法
    updateData(obj) {
      updateCharteredCity(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
          this.$message.success("修改成功");
        }
      });
    },
    // 表格数据接口渲染
    renderData() {
      queryCharteredCityList().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 弹窗确定按钮回调
    confirmFun() {
      if (!this.form.adCode) {
        this.$message.warning("请选择城市！");
        return;
      }
      if (this.tableData.some((i) => i.adCode == this.form.adCode)) {
        this.$message.warning("已存在该城市，请重新选择！");
        return;
      }
      saveCharteredCity(this.form).then((res) => {
        if (res.code == "SUCCESS") {
          this.renderData();
          this.closePopupFun();
          this.$message.success("新增成功");
        }
      });
    },
    // 取消按钮回调
    closePopupFun() {
      this.show = false;
      this.form = {};
    },
    //选择城市
    handleCascaderCity() {
      const node = this.$refs.cascader.getCheckedNodes()[0];
      const data = node.data;
      this.form.name = data.name;
      this.form.adCode = data.adcode;
      this.form.center = data.center;
      this.form.level = data.level;
      this.form.adCodes = node.path.join("/");
    },
    //获取城市代码
    getCityCode() {
      getCityCodeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.cityList = this.getTreeData(res.data[0].districts);
        }
      });
    },
    getTreeData(data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].districts.length < 1) {
          data[index].districts = undefined;
        } else {
          this.getTreeData(data[index].districts);
        }
      }
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.caterCityManage {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .header {
    height: 40px;
    margin-bottom: 18px;
    p {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
    }
    .el-button {
      float: right;
      margin-top: 2px;
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 30px;
}
</style>
